import(/* webpackMode: "eager" */ "/vercel/path0/components/GlobalStyles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/PreviewToolbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"/fonts/Aeonik-BoldItalic.woff2\",\"style\":\"italic\",\"weight\":\"bold\"},{\"path\":\"/fonts/Aeonik-Bold.woff2\",\"style\":\"normal\",\"weight\":\"bold\"},{\"path\":\"/fonts/Aeonik-RegularItalic.woff2\",\"style\":\"italic\",\"weight\":\"normal\"},{\"path\":\"/fonts/Aeonik-LightItalic.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"/fonts/Aeonik-Light.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"/fonts/Aeonik-Regular.woff2\",\"style\":\"normal\",\"weight\":\"normal\"}],\"fallback\":[\"helvetica\",\"arial\",\"sans-serif\"]}],\"variableName\":\"aeonik\"}");
