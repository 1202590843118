'use client'
import { Link, usePathname } from 'i18n/routing'
import { AnimatedUnderline, SlimUnderline } from './Underline'
import { useParams, useSearchParams } from 'next/navigation'
import { useLocale } from 'next-intl'

export function LanguageSwitcher() {
  const pathname = usePathname()
  const locale = useLocale()
  const params = useParams<any>()
  const searchParams = useSearchParams()
  return (
    <div>
      <Link
        href={{
          pathname,
          params,
          query: Object.fromEntries(searchParams.entries()),
        }}
        locale="de"
        className="relative overflow-hidden pb-12"
      >
        DE
        <span className="absolute bottom-[0.4rem] left-0 right-0">
          <AnimatedUnderline isActive={locale === 'de'}>
            <SlimUnderline />
          </AnimatedUnderline>
        </span>
      </Link>
      {' / '}
      <Link
        href={{
          pathname,
          params,
          query: Object.fromEntries(searchParams.entries()),
        }}
        locale="en"
        className="relative overflow-hidden pb-12"
      >
        EN
        <span className="absolute bottom-[0.4rem] left-0 right-0">
          <AnimatedUnderline isActive={locale === 'en'}>
            <SlimUnderline />
          </AnimatedUnderline>
        </span>
      </Link>
    </div>
  )
}
